import React from 'react';
// import PropTypes from 'prop-types';
//import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";


function CardAirOffer() {

    
    return(
        <div class="container-fluid pt-4 ">
<div class="row row-cols-2 row-cols-md-2" style={{marginTop: 30, marginBottom: 30}}>

    <div class="col">
        <div class="card mb-3" style={{marginLeft: '10%',  borderRadius: 20/ 2}} >
            <img src={imgnavbar.img32} class="card-img-top" alt="..."  style={{borderRadius: 20/ 2}} ></img>

        </div>
    </div>

    <div class="col">
        <div class="card mb-3" style={{marginLeft: '10%', borderRadius: 20/ 2}}>
            <img src={imgnavbar.img33} class="card-img-top" alt="..." style={{borderRadius: 20/ 2}} ></img>

        </div>
    </div>

    <div class="col">
        <div class="card mb-3" style={{marginLeft: '10%',  borderRadius: 20/ 2}} >
            <img src={imgnavbar.img34} class="card-img-top" alt="..."  style={{borderRadius: 20/ 2}} ></img>

        </div>
    </div>

</div>
        
</div>

    );

}

export default CardAirOffer;