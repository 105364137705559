import React from 'react';
import CardsOffer from '../components/CardsOffer/CardsOffer';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CardLand from '../components/CardLand/CardLand';
import CardLandOffer from '../components/CardLandOffer/CardLandOffer';


function Land (){
    

    return(
        <div>
            <Navbar></Navbar>
            <CardsOffer/>
            <h1 class="display-2 fw-bold" style={{marginTop: 40, marginLeft: 70}}>Transportation (Land)</h1>
            <CardLand/>
            <CardLandOffer/>
            <h1 class="display-2 fw-bold" style={{marginBottom: 40, textAlign: 'center'}}>Let's do it together...</h1>
            <Footer/>
        </div>
    )
}
export default Land;