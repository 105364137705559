import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom'; //, Redirect   activar con Logout
import Home from './views/Home';
import Land from './views/Land';
import Ocean from './views/Ocean';
import Air from './views/Air';
import AdditionalServices from './views/AdditionalServices';
import Automotive from './views/Automotive';
import Aerospace from './views/Aerospace';
import Manufacturing from './views/Manufacturing';
import Quotation from './views/Quotation';
import TrucksAvailabilityForm from './views/TrucksAvailabilityForm';
import Trucksform from './views/Trucksform';

 //const Logout = () =>{
 //   window.localStorage.removeItem('token');
 //   return <Redirect to="/" />
 //}
function Routes1(){
    return(
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} ></Route>
                <Route exact path="/land" element={<Land />} ></Route>
                <Route exact path="/ocean" element={<Ocean />} ></Route>
                <Route exact path="/air" element={<Air />} ></Route>
                <Route exact path="/additional" element={<AdditionalServices />} ></Route>
                <Route exact path="/automotive" element={<Automotive />} ></Route>
                <Route exact path="/aerospace" element={<Aerospace />} ></Route>
                <Route exact path="/manufacturing" element={<Manufacturing />} ></Route>
                <Route exact path="/quotation" element={<Quotation />} ></Route>
                <Route exact path="/trucksform" element={<TrucksAvailabilityForm />} ></Route>
                <Route exact path="/form" element={<Trucksform />} ></Route>
            </Routes>
        </Router>
    )
}
export default Routes1;