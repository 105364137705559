import React from 'react';
// import PropTypes from 'prop-types';
//import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";
import './CardManufacturing.scss';

function CardManufacturing() {

    
    return(

<div class="container-fluidManufacturing">
    <img src={imgnavbar.img64} srcset={`${imgnavbar.img62} 370w, ${imgnavbar.img63} 730w, ${imgnavbar.img64} 960w`} alt="..." style={{ width: '100%', height: '100%' }} class="imgManufacturing"></img>
    <div class="texto-encimaManufacturing" >
        Manufacturing requires that materials, spare parts and equipment are in time
        and form in order not to affect their production processes and their customers,
        with this in mind, MHS seeks to put its experience and resources in order to 
        make their supply chains lighter and more transparent without losing control
        of their materials and inventories.
    </div>
</div>
        

    );

}

export default CardManufacturing;