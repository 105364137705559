import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Grid, Card, Typography, CardContent, TextField, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

function Trucksform (){

    const [truck, setTruck] = useState({
        vendor_name: "",
        trucktype: "",
        countrystate: "",
        city: "",
        day: "",
        month: "",
        hour: "",
        created: "NOW()",
    });
    const [editing, setEditing] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(editing){
            await fetch(`https://www.mhslogistics.com.mx/trucks/${params.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(truck)
            })
        }else{
            await fetch("https://www.mhslogistics.com.mx/trucks", {
            method: "POST",
            body: JSON.stringify(truck),
            headers: {'Content-Type': 'application/json'}
        })
        }
        
        console.log(truck);
        navigate("/trucksform");
    }

    const handleChange = (e) => {
        setTruck({...truck, [e.target.name]: e.target.value});
    }

    const loadTruck = async (id) => {
        const res = await fetch(`https://www.mhslogistics.com.mx/${id}`)
        const data = await res.json();
        setTruck({vendor_name: data.vendor_name, trucktype: data.trucktype, countrystate: data.countrystate, city: data.city, day: data.day, month: data.month, hour: data.hour, created: data.created});
        setEditing(true);
    }


    useEffect(() => {
        if(params.id){
            loadTruck(params.id);
        }
    }, [params.id])
    

    return(
        <div>
            <Navbar></Navbar>
            <Grid container direction= 'column' alignItems='center' justifyContent='center'>
                <Grid item xs={3}/>
                    <Card sx={{mt: 5}} style={{backgroundColor: "#1e272e", padding:"1rem"}}>
                        <Typography variant="body2" textAlign="center" color="white">
                            {editing ? "Edit Truck availabilty" : "Create Truck availabilty"}
                        </Typography>
                        <CardContent>
                            <form onSubmit={handleSubmit}>
                            <TextField 
                                    variant='filled'
                                    label='Ingresa el nombre del proveedor'
                                    sx={{display: 'block', margin: '.5rem 0'}}

                                    name='vendor_name'
                                    value={truck.vendor_name}
                                    onChange={handleChange}
                                    inputProps={{style: {color: 'white'}}}
                                    InputLabelProps={{style: {color: 'white'}}}
                                ></TextField>
                                <TextField 
                                    variant='filled'
                                    label='Ingresa el tipo de Camion'
                                    sx={{display: 'block', margin: '.5rem 0'}}

                                    name='trucktype'
                                    value={truck.trucktype}
                                    onChange={handleChange}
                                    inputProps={{style: {color: 'white'}}}
                                    InputLabelProps={{style: {color: 'white'}}}
                                ></TextField>
                                <TextField 
                                    variant='filled'
                                    label='Ingresa el Estado del país'
                                    sx={{display: 'block', margin: '.5rem 0'}}

                                    name='countrystate'
                                    value={truck.countrystate}
                                    onChange={handleChange}
                                    inputProps={{style: {color: 'white'}}}
                                    InputLabelProps={{style: {color: 'white'}}}
                                ></TextField>
                                <TextField 
                                    variant='filled'
                                    label='Ingresa la ciudad'
                                    sx={{display: 'block', margin: '.5rem 0'}}

                                    name='city'
                                    value={truck.city}
                                    onChange={handleChange}
                                    inputProps={{style: {color: 'white'}}}
                                    InputLabelProps={{style: {color: 'white'}}}
                                ></TextField>
                                <TextField 
                                    variant='filled'
                                    label='Ingresa el día  dd'
                                    sx={{display: 'block', margin: '.5rem 0'}}

                                    name='day'
                                    value={truck.day}
                                    onChange={handleChange}
                                    inputProps={{style: {color: 'white'}}}
                                    InputLabelProps={{style: {color: 'white'}}}
                                ></TextField>
                                <TextField 
                                    variant='filled'
                                    label='Ingresa el mes mm'
                                    sx={{display: 'block', margin: '.5rem 0'}}

                                    name='month'
                                    value={truck.month}
                                    onChange={handleChange}
                                    inputProps={{style: {color: 'white'}}}
                                    InputLabelProps={{style: {color: 'white'}}}
                                ></TextField>
                                <TextField 
                                    variant='filled'
                                    label='Ingresa la hora hh:mm'
                                    sx={{display: 'block', margin: '.5rem 0'}}

                                    name='hour'
                                    value={truck.hour}
                                    onChange={handleChange}
                                    inputProps={{style: {color: 'white'}}}
                                    InputLabelProps={{style: {color: 'white'}}}
                                ></TextField>
                                <Button variant='contained' color='primary' type='submit'>
                                    Save
                                </Button>
                            </form>
                        </CardContent>
                    </Card>
            </Grid>
            <Footer/>
        </div>
    )
}    

export default Trucksform;