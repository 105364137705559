import React from 'react';
import CardsTrucks from '../components/CardsTrucksForm/CardsTrucks';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
//import TableTrucks from '../components/TableTrucks/TableTrucks';
//import { Button, Card, CardContent, Typography} from "@mui/material"
import { useEffect, useState } from "react";
import { DataGrid} from "@mui/x-data-grid";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";


function TrucksAvailabilityForm (){
    
    const [trucks, setTrucks] = useState([]);
    const navigate = useNavigate();

    const loadTrucks = async () => {
        const response = await fetch("https://www.mhslogistics.com.mx/trucks")
        const data = await response.json()
        setTrucks(data)
    }

    const handleDelete = async (id) => {
        const res = await fetch(`https://www.mhslogistics.com.mx/trucks/${id}`, {
            method: "DELETE"
        })
        const data = await res.json()
        setTrucks(trucks.filter((truck) => truck.id !== id));
        console.log(data)
    }

    useEffect(() => {
        loadTrucks();
    }, []);

    const columns = [
        {name:"id", label:"ID", value:parseInt("id")}, 
        {name:"vendor_name", label:"Vendor"}, 
        {name:"trucktype", label:"Truck Type"}, 
        {name:"countrystate", label:"State"}, 
        {name:"city",label:"City"}, 
        {name:"day", label:"Day"}, 
        {name:"month", label:"Month"}, 
        {name:"hour", label:"Hour"},
        /*{name:"action", label:"Edit", options: {customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <button onClick={() => navigate(`/form/${tableMeta.rowData[0]}/edit`)}>Edit</button>
            )
        }}},*/
        {name:"action", label:"Delete", options: {customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <button 
                    color="warning"
                    onClick={() => handleDelete(tableMeta.rowData[0])}>
                Delete
                </button>
            )
        }}}]

    return(
        <div>
            <Navbar></Navbar>
            <CardsTrucks/>

        

            {/* OPCION 3 MUI DATATABLES */}
            <MUIDataTable
                title={"Trucks availability per city"}
                columns={columns}
                data={trucks}
                options={{filterType: "checkbox"}}
                components={{}}
            />

            <Footer/>
        </div>
    )
}
export default TrucksAvailabilityForm;