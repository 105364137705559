import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";
import './CardsOffer.scss';

function CardsOffer() {

    
    return(
        <div class="container-fluid pt-4 ">
<div class="row row-cols-1 row-cols-md-4 g-4">

    <div class="col">
        <div class="card text-center" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Link to="/land">
            <img src={imgnavbar.img2} class="card-img-top" alt="..." style={{width: 75, height: 75, borderRadius: 400/ 2}}></img>
            <div class="card-body">
                <a href="#" class="btn btn-outline-primary">Tansportation Land</a>
            </div>
            </Link>
        </div>
    </div>

    <div class="col">
        <div class="card text-center" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Link to="/ocean">
            <img src={imgnavbar.img3} class="card-img-top" alt="..." style={{width: 75, height: 75, borderRadius: 400/ 2}}></img>
            <div class="card-body">
                <a href="#" class="btn btn-outline-primary">Tansportation Ocean</a>
            </div>
            </Link>
        </div>
    </div>

    <div class="col">
        <div class="card text-center" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Link to="/air">
            <img src={imgnavbar.img4} class="card-img-top" alt="..." style={{width: 75, height: 75, borderRadius: 400/ 2}}></img>
            <div class="card-body">
                <a href="#" class="btn btn-outline-primary">Tansportation Air</a>
            </div>
            </Link>
        </div>
    </div>

    <div class="col">
        <div class="card text-center" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Link to="/additional">
            <img src={imgnavbar.img5} class="card-img-top" alt="..." style={{width: 75, height: 75, borderRadius: 400/ 2}}></img>
            <div class="card-body">
                <a href="#" class="btn btn-outline-primary">Additional Services</a>
            </div>
            </Link>
        </div>
    </div>

</div>

 </div>       

    );

}

export default CardsOffer;
//Cards.propTypes={items:PropTypes.array}