import React from 'react';
import CardsOffer from '../components/CardsOffer/CardsOffer';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CardAerospace from '../components/CardAerospace/CardAerospace';



function Aerospace (){
    

    return(
        <div>
            <Navbar></Navbar>
            <CardsOffer/>
            <h1 class="display-2 fw-bold" style={{marginTop: 40, marginLeft: 70, textAlign: 'center'}}>Aerospace</h1>
            <CardAerospace/>
            <h1 class="display-2 fw-bold" style={{marginBottom: 40, textAlign: 'center'}}>Enjoy the experience...</h1>
            <Footer/>
        </div>
    )
}
export default Aerospace;