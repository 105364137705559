import React from 'react';
// import PropTypes from 'prop-types';
//import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";
import './CardAir.scss';

function CardAir() {

    
    return(

<div class="container-fluidAir">
    <img src={imgnavbar.img52} srcset={`${imgnavbar.img50} 370w, ${imgnavbar.img51} 730w, ${imgnavbar.img52} 960w`} alt="..." style={{ width: '100%', height: '100%' }}></img>
    <div class="texto-encimaAir" >
        MHS air freight services are a trustworthy option for air freight of all types. 
        Wheter you're shipping hazardous cargo or any commodity that needs fast delivery,
        we can provide the solution that meets your needs.
    </div>
</div>
        

    );

}

export default CardAir;