import mhslogo from '../images/MHS_Logo.jpg';
import tland from '../images/TranspLand.gif';
import tocean from '../images/TransportationOcean.gif';
import tair from '../images/TransportationAir.gif';
import servadd from '../images/additionalServices.gif';
import automotive from '../images/Automotive.gif';
import aerospace from '../images/Aerospace.gif';
import manufacturing from '../images/Manufacturing.gif';
import bestop from '../images/Bestop.png';
import bocar from '../images/Bocar.png';
import bosch from '../images/Bosch.png';
import elringk from '../images/ElringKlinger.png';
import magna from '../images/Magna.jfif';
import dynacast from '../images/Dynacast.png';
import tenneco from '../images/Tenneco.jfif';
import trw from '../images/TRW.png';
import federalm from '../images/FederalMogul.png';
import email from '../images/email.png';
import informacion from '../images/informacion.png';
import linkedin from '../images/linkedin.png';
import transpland from '../images/TranspLand.jpg';
import dryvan from '../images/TruckloadDryVan.png';
import ltlland from '../images/LTL.png';
import milkrun from '../images/MilkRun.png';
import expedited from '../images/ExpeditedTransport.png';
import distribution from '../images/Distribution.png';
import flatbed from '../images/Flatbed.png';
import transpocean from '../images/TransportationOcean.jpg';
import fcllcl from '../images/FCL_LCL.png';
import lclfcl from '../images/LCL_FCL.jpg';
import transpair from '../images/TransportationAir.jpg';
import ltlair from '../images/LTLair.png';
import charter from '../images/Charter.png';
import handcourier from '../images/HandCourier.png';
import addserv from '../images/additionalServices.jpg';
import custombrokerage from '../images/CustomsBrokerage.png';
import reverselog from '../images/ReverseLogistic.png';
import warehouse from '../images/Warehouse.png';
import specialhandling from '../images/SpecialHandling.png';
import schainconsulting from '../images/SCconsulting.png';
import automotived from '../images/Automotive.jpg';
import aerospaced from '../images/Aerospace.jpg';
import manufacturingd from '../images/Manufacturing.jpg';
import transplandSmall from '../images/TranspLand_370x247.png';
import transplandMedium from '../images/TranspLand_730x486.png';
import transplandLarge from '../images/TranspLand_960x640.png';
import transpoceanSmall from '../images/TransportationOcean_370x247.png';
import transpoceanMedium from '../images/TransportationOcean_730x486.png';
import transpoceanLarge from '../images/TransportationOcean_960x640.png';
import transpairSmall from '../images/TransportationAir_370x247.png';
import transpairMedium from '../images/TransportationAir_730x486.png';
import transpairLarge from '../images/TransportationAir_960x640.png';
import addservSmall from '../images/additionalServices_370x247.png';
import addservMedium from '../images/additionalServices_730x486.png';
import addservLarge from '../images/additionalServices_960x640.png';
import automotivedSmall from '../images/Automotive370x247.png';
import automotivedMedium from '../images/Automotive_730x486.png';
import automotivedLarge from '../images/Automotive_960x640.png';
import aerospacedSmall from '../images/Aerospace_370x247.png';
import aerospacedMedium from '../images/Aerospace_730x486.png';
import aerospacedLarge from '../images/Aerospace_960x640.png';
import manufacturingdSmall from '../images/Manufacturing_370x247.png';
import manufacturingdMedium from '../images/Manufacturing_730x486.png';
import manufacturingdLarge from '../images/Manufacturing_960x640.png';
import camion3_5 from '../images/3_5T238x143.png';
import pickup from '../images/pickup238x143.png';
import rabon from '../images/rabon238x143.png';
import torton from '../images/torton238x143.png';
import trailerCaja from '../images/Trailer_Caja238x143.png';
import trailerPlataforma from '../images/Trailer_plataforma238x143.png';


export default{
    "img1": mhslogo,
    "img2": tland,
    "img3": tocean,
    "img4": tair,
    "img5": servadd,
    "img6": automotive,
    "img7": aerospace,
    "img8": manufacturing,
    "img9": bestop,
    "img10": bocar,
    "img11": bosch,
    "img12": elringk,
    "img13": magna,
    "img14": dynacast,
    "img15": tenneco,
    "img16": trw,
    "img17": federalm,
    "img18": email,
    "img19": informacion,
    "img20": linkedin,
    "img21": transpland,
    "img22": dryvan,
    "img23": ltlland,
    "img24": milkrun,
    "img25": expedited,
    "img26": distribution,
    "img27": flatbed,
    "img28": transpocean,
    "img29": fcllcl,
    "img30": lclfcl,
    "img31": transpair,
    "img32": ltlair,
    "img33": charter,
    "img34": handcourier,
    "img35": addserv,
    "img36": custombrokerage,
    "img37": reverselog,
    "img38": warehouse,
    "img39": specialhandling,
    "img40": schainconsulting,
    "img41": automotived,
    "img42": aerospaced,
    "img43": manufacturingd,
    "img44": transplandSmall,
    "img45": transplandMedium,
    "img46": transplandLarge,
    "img47": transpoceanSmall,
    "img48": transpoceanMedium,
    "img49": transpoceanLarge,
    "img50": transpairSmall,
    "img51": transpairMedium,
    "img52": transpairLarge,
    "img53": addservSmall,
    "img54": addservMedium,
    "img55": addservLarge,
    "img56": automotivedSmall,
    "img57": automotivedMedium,
    "img58": automotivedLarge,
    "img59": aerospacedSmall,
    "img60": aerospacedMedium,
    "img61": aerospacedLarge,
    "img62": manufacturingdSmall,
    "img63": manufacturingdMedium,
    "img64": manufacturingdLarge,
    "img65": camion3_5,
    "img66": pickup,
    "img67": rabon,
    "img68": torton,
    "img69": trailerCaja,
    "img70": trailerPlataforma
}