import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";
import './CardsIndustry.scss';


function CardsIndustry() {

    
    return(
       
        <div class="containerIndustry">

   
            <div class="card text-center Industry">
            <Link to="/automotive">
                    <img src={imgnavbar.img6} class="card-img-top" alt="Card image" style={{borderRadius: 20/ 2}}></img>
                <div class="card-body Industry">
                    <a href="#" class="btn btn-outline-primary btn-sm">Automotive</a>
                </div>
            </Link>
            </div>



            <div class="card text-center Industry">
            <Link to="/aerospace">
                    <img src={imgnavbar.img7} class="card-img-top" alt="..." style={{borderRadius: 20/ 2}}></img>
                <div class="card-body Industry">
                    <a href="#" class="btn btn-outline-primary btn-sm">Aerospace</a>
                </div>
                </Link>
            </div>



            <div class="card text-center Industry">
            <Link to="/manufacturing">
                    <img src={imgnavbar.img8} class="card-img-top" alt="..." style={{ borderRadius: 20/ 2}}></img>
                <div class="card-body Industry">
                    <a href="#" class="btn btn-outline-primary btn-sm">Manufacturing</a>
                </div>
                </Link>
            </div>


        </div> 

    );

}

export default CardsIndustry;



