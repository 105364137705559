import React from "react";
import {Link} from 'react-router-dom';
import './Navbar.scss';
import imgnavbar from "../../images/imgnavbar";
import {useAuth0} from "@auth0/auth0-react";
import LogoutButton from "../Buttons/LogoutButton";
import LoginButton from "../Buttons/LoginButton";





function Navbar() {

    const { isAuthenticated} = useAuth0();


    if (isAuthenticated) {
        return (
            <nav>
                <div class="containerNavbar">
                    
                    <div class="containerLogoButtons">
                        <div>
                            <a class="navbar-brand" href="#">
                            <img src={imgnavbar.img1} alt="" width="90" height="90"></img>
                            </a>
                        </div>
                        <div>
                            <Link className="nav-link " to="/" style={{paddingTop: "3.5%"}}>
                                <button type="button" class="btn btn-outline-primary btn-sm">Home</button> 
                            </Link>
                        </div>
                        <div>
                            <Link className="nav-link" to="/quotation" tabindex="-1" style={{paddingTop: "3.5%"}}>
                                <button type="button" class="btn btn-outline-success btn-sm">Get a Quote</button>
                            </Link>
                        </div>
        
                    </div>   

                    <div class="containerFormNavbar">
                        <div></div>
                        <div>
                            <LogoutButton />
                        </div> 
                        <div>
                            <Link className="nav-link" to="/trucksform" tabindex="-1" style={{paddingTop: "3.5%"}}>
                                <button type="button" class="btn btn-info btn-sm">Trucks Tracking</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
         );
    } else {
        return (
            <nav>
                <div class="containerNavbar">
                    
                    <div class="containerLogoButtons">
                        <div>
                            <a class="navbar-brand" href="#">
                            <img src={imgnavbar.img1} alt="" width="90" height="90"></img>
                            </a>
                        </div>
                        <div>
                            <Link className="nav-link " to="/" style={{paddingTop: "3.5%"}}>
                                <button type="button" class="btn btn-outline-primary btn-sm">Home</button> 
                            </Link>
                        </div>
                        <div>
                            <Link className="nav-link" to="/quotation" tabindex="-1" style={{paddingTop: "3.5%"}}>
                                <button type="button" class="btn btn-outline-success btn-sm">Get a Quote</button>
                            </Link>
                        </div>
        
                    </div>   


                    <div class="containerFormNavbar">
                        <div class="auxiliarLogin"></div>
                        <div class="auxiliarLogin"></div>
                        <div>
                                <LoginButton />
                        </div> 
                    </div>
                </div>                
            </nav>
         );
    }
    
}
        


  

export default Navbar;