import React from 'react';
// import PropTypes from 'prop-types';
//import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";
import './CardLand.scss';

function CardLand() {

    
    return(

<div class="containerLand">
    <img src={imgnavbar.img46}  srcset={`${imgnavbar.img44} 370w, ${imgnavbar.img45} 730w, ${imgnavbar.img46} 960w`} alt="..." style={{ width: '100%', height: '100%' }}></img>
    <div class="texto-encimaLand" >
        MHS provides all forms of land transport and multimodal transport. No matter what type of
        goods you're transporting. Trust top -quality carriers to meet your needs. Sensitive 
        freight, over dimensions, especial locations- we've got you covered, start to finish.
    </div>
</div>
        

    );

}

export default CardLand;

