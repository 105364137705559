import React from 'react';
// import PropTypes from 'prop-types';
//import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";


function CardAddServ() {

    
    return(

<div class="container-fluid">
    <img src={imgnavbar.img55} srcset={`${imgnavbar.img53} 370w, ${imgnavbar.img54} 730w, ${imgnavbar.img55} 960w`} alt="..." style={{ width: '100%', height: '100%' }}></img>
</div>
        

    );

}

export default CardAddServ;