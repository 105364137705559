import React from 'react';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
//import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";
import './Footer.scss';


function Footer() {

    
    return(
        
        <div class="containerFooter">

            <div class="colFooter">
                    <div class="colFooterImg">
                        <img src={imgnavbar.img18} class="imgFooter" alt="..." ></img>
                    </div>
                    <div class="textCardFooter">
                        <p class="textCardPFooter" >sales@mhslog.com</p>
                    </div>
            </div>

            <div class="colFooter">
                    <div class="colFooterImg">
                        <img src={imgnavbar.img19} class="imgFooterPhone" alt="..." ></img>
                    </div>
                    <div class="card-body">
                        <p class="textCardPFooterPhone" >(52) 55 - 5343 -0933</p>
                        <p class="textCardPFooterPhone" >(210) 607 - 4804</p>
                    </div>
            </div>

            <div class="colFooter">
                <div class="colFooterImg">
                    <Link to={{ pathname: "https://www.linkedin.com/company/mhs-logistics/about/.html" }} target="_blank">
                        <img src={imgnavbar.img20} class="imgFooterLI" alt="..." ></img>
                    </Link>
                </div>
            </div>

            <div class="col" id="externo" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                Iconos diseñados por <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.es/" title="Flaticon">www.flaticon.es</a>
            </div>

        </div>     

    );

}

export default Footer;

