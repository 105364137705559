import React from 'react';
import CardsOffer from '../components/CardsOffer/CardsOffer';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CardAir from '../components/CardAir/CardAir';
import CardAirOffer from '../components/CardAirOffer/CardAirOffer';


function Air (){
    

    return(
        <div>
            <Navbar></Navbar>
            <CardsOffer/>
            <h1 class="display-2 fw-bold" style={{marginTop: 40, marginLeft: 70}}>Transportation (Air)</h1>
            <CardAir/>
            <CardAirOffer/>
            <h1 class="display-2 fw-bold" style={{marginBottom: 40, textAlign: 'center'}}>Let's do it together...</h1>
            <Footer/>
        </div>
    )
}
export default Air;