import React from 'react';
import CardsOffer from '../components/CardsOffer/CardsOffer';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CardAddServ from '../components/CardAddServ/CardAddServ';
import CardAddServOffer from '../components/CardAddServOffer/CardAddServOffer';


function additionalServices (){
    

    return(
        <div>
            <Navbar></Navbar>
            <CardsOffer/>
            <h1 class="display-2 fw-bold" style={{marginTop: 40, marginLeft: 70, textAlign: 'center'}}>Additional Services</h1>
            <CardAddServ/>
            <CardAddServOffer/>
            <h1 class="display-2 fw-bold" style={{marginBottom: 40, textAlign: 'center'}}>Let's do it together...</h1>
            <Footer/>
        </div>
    )
}
export default additionalServices;