import React from 'react';
// import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";
import './CardsTrucks.scss';


function CardsTrucks() {

    
    return(

        <div class="containerTrucks">


            <div class="smallTrucks">

                <div class="card text-center Trucks">
                    <Link to="/form">
                        <img src={imgnavbar.img66} class="card-img-top" alt=" " style={{borderRadius: 20/ 2}}></img>
                        <div class="card-body Trucks01">
                            <a href="#" class="btn btn-success btn-sm">Pickup</a>
                        </div>
                    </Link>
                </div>

                <div class="card text-center Trucks">
                    <Link to="/form">
                        <img src={imgnavbar.img65} class="card-img-top" alt="..." style={{borderRadius: 20/ 2}}></img>
                        <div class="card-body Trucks01">
                            <a href="#" class="btn btn-success btn-sm">Camion 3.5</a>
                        </div>
                    </Link>
                </div>

                <div class="card text-center Trucks">
                    <Link to="/form">
                        <img src={imgnavbar.img67} class="card-img-top" alt="..." style={{ borderRadius: 20/ 2}}></img>
                        <div class="card-body Trucks01">
                            <a href="#" class="btn btn-success btn-sm">Rabon</a>
                        </div>
                    </Link>
                </div>

            </div>



            <div class="bigTrucks">

                <div class="card text-center Trucks">
                    <Link to="/form">
                        <img src={imgnavbar.img68} class="card-img-top" alt=" " style={{borderRadius: 20/ 2}}></img>
                        <div class="card-body Trucks01">
                            <a href="#" class="btn btn-success btn-sm">Torton</a>
                        </div>
                    </Link>
                </div>

                <div class="card text-center Trucks">
                    <Link to="/form">
                        <img src={imgnavbar.img69} class="card-img-top" alt="..." style={{borderRadius: 20/ 2}}></img>
                        <div class="card-body Trucks01">
                            <a href="#" class="btn btn-success btn-sm">Trailer caja</a>
                        </div>
                    </Link>
                </div>

                <div class="card text-center Trucks">
                    <Link to="/form">
                        <img src={imgnavbar.img70} class="card-img-top" alt="..." style={{ borderRadius: 20/ 2}}></img>
                        <div class="card-body Trucks01">
                            <a href="#" class="btn btn-success btn-sm">Trailer plataforma</a>
                        </div>
                    </Link>
                </div>
                
            </div>

        </div>       

    );

}

export default CardsTrucks;



