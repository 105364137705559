import React from 'react';
// import PropTypes from 'prop-types';
//import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";
import './CardOcean.scss';

function CardOcean() {

    
    return(

<div class="container-fluidOcean">
    <img src={imgnavbar.img49} srcset={`${imgnavbar.img47} 370w, ${imgnavbar.img48} 730w, ${imgnavbar.img49} 960w`} alt="..." style={{ width: '100%', height: '100%' }}></img>
    <div class="texto-encimaOcean" >
        MHS is commited to providing a series of logistics services to and from China,
        Latin America and Europe, and we have good long-term cooperation with carriers
        like MSK, MSC, COSCO, HPL, APL, etc., which can ensure you high-quality and 
        effective transport.
    </div>
</div>
        

    );

}

export default CardOcean;