import React, { useState } from 'react';
import CardsOffer from '../components/CardsOffer/CardsOffer';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';
//import { render } from '@testing-library/react';


function Quotation() {

    const [error, setError] = useState(null);
    const [sent, setSent] = useState(false);
    
    const [to, setTo] = useState('');
    const [cc, setCc] = useState('');
    const [subject, setSubject] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [text, setText] = useState('');
   // const [selectedFile, setSelectedFile] = useState(null);
   // const [fileSelected,setFileSelected] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        {/*const formData = new FormData();
        formData.append('to',to);
        formData.append('cc',cc);
        formData.append('subject',subject);
        formData.append('email',email);
        formData.append('phone',phone);
        formData.append('text',text);
        formData.append('selectedFile',fileSelected);*/} 

        try {
        //    console.log(formData.get('selectedFile'))
        //await axios.post('/api/mail', formData);
          await axios.post('/api/mail', { to, cc, subject, email, phone, text  }); //selectedFile
         
          setSent(true);
          setError(null);
        } catch (err) {
          setError(err.response.data);
        }
      };

      //const changeImg = (e)=>{ //Freelancer
      //    setFileSelected(e.target.files[0]);
      //  }

    return(
        <div>
            <Navbar></Navbar>
            <CardsOffer/>
                <div class= "container p-4 Quotation">
                    <div class="row">
                        <div class="col-md-6 offset-md-3">
                            <div class="card">
                                <div class="card-body">
                                    <form onSubmit={handleSubmit} class="formQuotation">
                                        {/*<div class="form-group">
                                            <input 
                                            value={to}
                                            onChange={(e) => setTo(e.target.value)}
                                            type="text" 
                                            name="to" 
                                            class="form-control" 
                                            placeholder="To"></input>
                                        </div> */}

                                        <div class="form-group">
                                            <input 
                                            value={cc}
                                            onChange={(e) => setCc(e.target.value)}
                                            type="email" 
                                            name="cc" 
                                            class="form-control" 
                                            placeholder="Your email addresse"></input>
                                        </div>

                                        <div class="form-group">
                                            <input 
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            type="text" 
                                            name="subject" 
                                            class="form-control" 
                                            placeholder="Subject"></input>
                                        </div>

                                        {/*<div class="form-group">
                                            <input 
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            type="email" 
                                            name="email" 
                                            class="form-control" 
                                            placeholder="Email"></input>
                                        </div> */}

                                        <div class="form-group">
                                            <input 
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            type="text" 
                                            name="phone" 
                                            class="form-control" 
                                            placeholder="Your phone number"></input>
                                        </div>

                                        <div class="form-group">
                                            <textarea 
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                            name="message" 
                                            rows="10" 
                                            class="form-control" 
                                            placeholder="Your message"></textarea>
                                        </div>

                                        {/*<div class="form-group">
                                            <input 
                                            class="form-control"
                                            value={selectedFile}
                                            //onChange={(e)=> setSelectedFile(e.target.files[0])}
                                            onChange={changeImg}
                                            type="file"
                                            name="selectedFile" 
                                            multiple></input>
                                    </div>*/}

                                        <button type="submit" class="btn btn-primary">Submit</button>
                                    </form>
                                    {error && error}
                                    {sent && <p class="display-2 fw-bold" style={{ textAlign: 'center' }}>Email sent!</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer/>
        </div>
    );
}



export default Quotation;