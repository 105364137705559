import React from 'react';
// import PropTypes from 'prop-types';
//import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";
import './CardsCustomers.scss';


function CardsCustomers() {

    
    return(
        <div class="containerCustomers">

            <div class="col">
        
                <img src={imgnavbar.img9} class="cardCustomer" alt="..." style={{borderRadius: 20/ 2}}></img>
       
            </div>

            <div class="col">
                
                    <img src={imgnavbar.img10} class="cardCustomer" alt="..." style={{borderRadius: 20/ 2}}></img>
                
            </div>

            <div class="col">
                
                    <img src={imgnavbar.img11} class="cardCustomer" alt="..." style={{borderRadius: 20/ 2}}></img>
                
            </div>

            <div class="col">
                
                    <img src={imgnavbar.img12} class="cardCustomer" alt="..." style={{borderRadius: 20/ 2}}></img>
                
            </div>

            <div class="col">
                
                    <img src={imgnavbar.img13} class="cardCustomer" alt="..." style={{borderRadius: 20/ 2}}></img>
                
            </div>

            <div class="col">
                
                    <img src={imgnavbar.img15} class="cardCustomer" alt="..." style={{borderRadius: 20/ 2}}></img>
                
            </div>

            <div class="col">
                
                    <img src={imgnavbar.img14} class="cardCustomer" alt="..." style={{borderRadius: 20/ 2}}></img>
                
            </div>

            <div class="col">
                
                    <img src={imgnavbar.img16} class="cardCustomer" alt="..." style={{borderRadius: 20/ 2}}></img>
                
            </div>

            <div class="col">
                
                    <img src={imgnavbar.img17} class="cardCustomer" alt="..." style={{borderRadius: 20/ 2}}></img>
                
            </div>


</div>
     

    );

}

export default CardsCustomers;

