import React from 'react';
// import PropTypes from 'prop-types';
//import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";
import './CardAerospace.scss';

function CardAerospace() {

    
    return(

<div class="container-fluidAerospace">
    <img src={imgnavbar.img61} srcset={`${imgnavbar.img59} 370w, ${imgnavbar.img60} 730w, ${imgnavbar.img61} 960w`} alt="..." style={{ width: '100%', height: '100%' }}></img>
    <div class="texto-encimaAerospace" >
        We are always working together with our customers to guarantee the proper handling and
        transportation of their parts, avoiding damages and delays in deliveries, assisted by 
        our team and their experience.
    </div>
</div>
        

    );

}

export default CardAerospace;