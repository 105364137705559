import React from 'react';
import CardsOffer from '../components/CardsOffer/CardsOffer';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CardOcean from '../components/CardOcean/CardOcean';
import CardOceanOffer from '../components/CardOceanOffer/CardOceanOffer';


function Ocean (){
    

    return(
        <div>
            <Navbar></Navbar>
            <CardsOffer/>
            <h1 class="display-2 fw-bold" style={{marginTop: 40, marginLeft: 70}}>Transportation (Ocean)</h1>
            <CardOcean/>
            <CardOceanOffer/>
            <h1 class="display-2 fw-bold" style={{marginBottom: 40, textAlign: 'center'}}>Let's do it together...</h1>
            <Footer/>
        </div>
    )
}
export default Ocean;