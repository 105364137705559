import React from 'react';
import CardsCustomers from '../components/CardsCustomers/CardsCustomers';
import CardsIndustry from '../components/CardsIndustry/CardsIndustry';
import CardsOffer from '../components/CardsOffer/CardsOffer';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import './Home.scss';

function Home (){
    

    return(
        <div class="Homediv">
            <Navbar></Navbar>
            <CardsOffer/>
                <h1 class="HomeTop" >Do you have a logistic need?</h1>
                <h1 class="HomeTopP" >Do you need a tailor suit solution?</h1>
                <h1 class="HomeTopP" >Do you need a partner to do it?</h1>
                <h2 class="HomeTopA" >You have landed to the right place!...</h2>
            <CardsIndustry/>
                <h1 class="display-2 fw-bold HomeCustomers" style={{marginTop: 20, marginBottom: 40, textAlign: 'center'}}>Customers</h1>
            <CardsCustomers/>
                <h1 class="HomeBottom" >We are in since 2008</h1>
                <h1 class="HomeBottom" >Enjoy the experience...</h1>
            <Footer/>
        </div>
    )
}
export default Home;