import React from 'react';
// import PropTypes from 'prop-types';
//import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";
import './CardAutomotive.scss';

function CardAutomotive() {

    
    return(

<div class="container-fluidAutomotive">
    <img src={imgnavbar.img58} srcset={`${imgnavbar.img56} 370w, ${imgnavbar.img57} 730w, ${imgnavbar.img58} 960w`} alt="..." style={{ width: '100%', height: '100%' }}></img>
    <div class="texto-encimaAutomotive" >
        Our target all the time is provided JIT services that can help you to improve your core
        business. We can help you to see all your supply chain, with that, we can help you to do
        synergies into your process, that can help all of us to work together to reduce your
        logistic cost.
    </div>
</div>
        

    );

}

export default CardAutomotive;