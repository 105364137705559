import React from 'react';
// import PropTypes from 'prop-types';
//import { Link } from "react-router-dom";
import imgnavbar from "../../images/imgnavbar";


function CardLandOffer() {

    
    return(
       
        <div class="container-fluid pt-4 ">
<div class="row row-cols-2 row-cols-sm-3 " style={{marginTop: 30, marginBottom: 30}}>

    <div class="col">
        <div class="card mb-3" style={{marginLeft: '10%',borderRadius: 20/ 2}} >
            <img src={imgnavbar.img22} class="card-img-top" alt="..."  style={{borderRadius: 20/ 2}} ></img>

        </div>
    </div>

    <div class="col">
        <div class="card mb-3" style={{ marginLeft: '10%', borderRadius: 20/ 2}}>
            <img src={imgnavbar.img23} class="card-img-top" alt="..." style={{borderRadius: 20/ 2}} ></img>

        </div>
    </div>

    <div class="col">
        <div class="card mb-3" style={{marginLeft: '10%', borderRadius: 20/ 2}}>
            <img src={imgnavbar.img24} class="card-img-top" alt="..." style={{borderRadius: 20/ 2}}></img>
            
        </div>
    </div>

    <div class="col">
        <div class="card mb-3" style={{marginLeft: '10%', borderRadius: 20/ 2}}>
            <img src={imgnavbar.img25} class="card-img-top" alt="..." style={{borderRadius: 20/ 2}} ></img>

        </div>
    </div>

    <div class="col">
        <div class="card mb-3" style={{marginLeft: '10%',  borderRadius: 20/ 2}}>
            <img src={imgnavbar.img26} class="card-img-top" alt="..." style={{borderRadius: 20/ 2}}></img>
            
        </div>
    </div>

    <div class="col">
        <div class="card mb-3" style={{ marginLeft: '10%', borderRadius: 20/ 2}}>
            <img src={imgnavbar.img27} class="card-img-top" alt="..." style={{borderRadius: 20/ 2}} ></img>

        </div>
    </div>

    </div>

</div>
        

    );

}

export default CardLandOffer;